// Table css
@import '@scss/common.scss';

.mfnw__borderless_card-table {
    border-collapse: separate;
    table-layout: auto;
    border-spacing: 0 20px;

    thead tr > th {
        padding: 0 1.5rem;
        color: $text-light;
        font-size: 0.75rem;
    }

    tbody {
        tr {
            background-color: $white;
            border-radius: 12px;
            td {
                color: $text-light;
                font-size: 0.875rem;
                &:first-child {
                    border-radius: 12px 0 0 12px;
                    font-weight: bold;
                    color: $text-dark;
                }
                &:last-child {
                    border-radius: 0 12px 12px 0;
                }
            }
        }
    }
}
.mfnw__borderless_card-table{
    .driver_header_name{
        font-size: 10px;
        font-weight: 700;
        @include media-breakpoints-up-lg{
            font-size: 0.75rem;
        }
    }
   }
.add_more_contact_btn {
    border: 2px dashed $primary;
    border-radius: 6px;
    padding: 1rem;
}
.viewmod-icon {
    .color {
        color: #b7b7b7;
    }
}
.main-filecontrol {
    .rt-step5-cmn-upload-box {
        max-width: 570px;
        width: 100%;
        height: 190px;
        border: 2px dashed #b7b7b7;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        margin-bottom: 30px;
        .rt-step5-cmn-upload-text {
            padding-top: 10px;
            color: $primary;
            font-weight: 500;
            line-height: 23px;
            text-align: center;
        }
        .rt-step-5-cmn-file-text {
            max-width: 133px;
            width: 100%;
            font-size: 12px;
            line-height: 15px;
            color: #686868;
            text-align: center;
            .rt-cmn-browse-text {
                color: $secondary;
                cursor: pointer;
            }
        }
    }
}
.main-body-section {
    .card-addingborder {
        border: 3px dashed #898989;
    }
    .main-background {
        background-color: #f6f6f6;
        padding: 0px 5px 5px 5px;
        border-radius: 5px;
    }
    .second-background {
        background-color: #e4edf3;
    }

    .form-control-serch1 {
        background: #efeaea;
        width: 24px;
        height: 24px;
        color: #363636;
    }
}
.mf-addRegion {
    .icon {
        color: #0270b2;
    }
}
.top_filter_row {
    .dropdown_filter-top {
        height: 45px;
        @include media-breakpoints-up-sm {
            height: unset;
        }
    }
}

$font-family: 'Roboto';
$min-contrast-ratio: 2 !default;
$primary: #0270b2;
$linear-gradient: linear-gradient(180deg, #3aa6de 0%, #2660aa 100%);
$secondary: #00ae8b;
$pink: #bc00ff;
$success: #7cbb00;
$info: #4681b6;
$danger: #f65314;
$warning: #ffab00;
$lightpink: #f58b7f;
$iconcolor: #b7b7b7;
$white: #fff;
$light: #898989 !default;
$dark: #363636 !default;
$disabled: #d3d3d3 !default;

// navbar
$nav-link-font-weight: 600;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-toggler-border-radius: 6px;
$navbar-toggler-padding-y: 0.5rem;
$navbar-toggler-padding-x: 0.5rem;
$navbar-toggler-font-size: 0.875rem;
$text-light: #898989;
$text-dark: #363636;
$bg-color: #f6f6f6;
$input-border-color: #dddddd;

// input group
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$secondary}'/></svg>") !default;
$input-group-addon-padding-y: 12px;
$input-group-addon-padding-x: 16px;
$input-group-addon-color: $text-light;
$input-group-addon-bg: $bg-color;
$input-group-addon-border-color: $input-border-color;

// button
$btn-padding-y: 14px;
$btn-padding-x: 20px;
$btn-font-size: 14px;
$btn-line-height: 16px;
$btn-font-size-lg: $btn-font-size;
$btn-border-radius: 4px !default;
$btn-border-radius-lg: $btn-border-radius !default;

// form/input
$form-check-input-width: 1.5em !default;
$breadcrumb-divider: quote('>');
$list-group-item-padding-y: 1rem;
$list-group-item-padding-x: 1rem;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='#B7B7B7' stroke='#B7B7B7' stroke-width='2' d='m2 6 6 6 6-6'/></svg>");
$badge-font-weight: 500 !default;

// Modal
$zindex-modal-backdrop: 2024 !default;
$zindex-modal: 2050 !default;

// dropdown
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1.25rem;
$dropdown-border-width: 0;
$dropdown-min-width: 12rem;

// table

$table-cell-padding-y: 2rem;
$table-cell-padding-x: 1.5rem;
$table-bg: transparent;
$table-cell-vertical-align: middle;

// accordion

$accordion-bg: transparent !default;
$accordion-bg: transparent !default;
$accordion-button-active-bg: transparent !default;
$accordion-button-bg: transparent !default;
$accordion-border-width: 0 !default;
$accordion-button-focus-border-color: none !default;
$accordion-button-focus-box-shadow: none !default;
$accordion-padding-y: 0 !default;
$accordion-padding-x: 0 !default;
$accordion-button-icon: url("data:image/svg+xml,<svg width='12' height='6' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.4404 9.56055C7.02633 10.1465 7.9779 10.1465 8.56383 9.56055L14.5638 3.56055C14.9951 3.1293 15.1216 2.48711 14.8873 1.92461C14.6529 1.36211 14.1091 0.996484 13.4998 0.996484H1.49977C0.895084 0.996484 0.346646 1.36211 0.112271 1.92461C-0.122104 2.48711 0.00914574 3.1293 0.435709 3.56055L6.43571 9.56055H6.4404Z' fill='#fff'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg width='12' height='6' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8.5596 0.439453C7.97367 -0.146484 7.0221 -0.146484 6.43617 0.439453L0.436165 6.43945C0.00491539 6.8707 -0.121647 7.51289 0.112728 8.07539C0.347103 8.63789 0.890853 9.00352 1.50023 9.00352H13.5002C14.1049 9.00352 14.6534 8.63789 14.8877 8.07539C15.1221 7.51289 14.9909 6.8707 14.5643 6.43945L8.56429 0.439453H8.5596Z' fill='#fff'/></svg>") !default;
$accordion-icon-transform: none !default;

// list group

$list-group-border-width: 0;
$list-group-border-radius: none;
$list-group-item-padding-x: 1.25rem;

// progresh bar
$progress-height: 0.2rem;
$progress-bar-bg: #44a6e0;
// diver view

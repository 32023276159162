@import '@scss/common.scss';

.mfnw__form-fixed {
    margin: 0 auto;

    h3 {
        letter-spacing: 1.5pt;
    }
}
.mfnw_create_title{
    display: block;
    @include media-breakpoints-up-lg{
        display: none;
    }
}
    .mfnw__form-logo_respons{
        display: none;
        @include media-breakpoints-up-lg {
           display: block;
        }
    }

@import '@scss/common.scss';

.login_auth_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include media-breakpoints-up-lg {
        min-height: 100vh;
        flex-wrap: nowrap;
        flex-direction: row;
    }
    @include media-breakpoints-up-xl {
        flex-wrap: nowrap;
    }
    .card {
        &.leftBox {
            width: 100%;
            border: 0;
            height: 250px;
            @include media-breakpoints-up-lg {
                max-width: 400px;
                height: auto;
            }
            .bannerWrapper {
                background: linear-gradient(
                    188.68deg,
                    #1ec186 0%,
                    #00ae8b 100%
                );
                background: linear-gradient(
                    188.68deg,
                    #1ec186 0%,
                    #00ae8b 100%
                );
                border-radius: 0px 0px 6px 6px;
                height: 100%;
                display: flex;
                flex-direction: column;
                @include media-breakpoints-up-lg {
                    border-radius: 6px 0 0 6px;
                    justify-content: space-between;
                }

                .logo-wrapper {
                    padding: 50px 0px;
                    text-align: center;
                    @include media-breakpoints-up-lg {
                        padding: 30px;
                        text-align: left;
                    }
                }
                h4 {
                    max-width: 170px;
                    width: 100%;
                    font-family: $font-family;
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: 1px;
                    color: #fff;
                    margin: 0 auto;
                    text-align: center;

                    span {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32px;
                    }
                }
                span {
                    font-family: $font-family;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    padding-bottom: 1rem;
                    display: block;
                    color: #fff;
                }
                .mfnw-hide-footer {
                    display: none;
                    @include media-breakpoints-up-lg {
                        display: block;
                    }
                }
            }
        }
    }
    .card {
        &.rightBox {
            width: 100%;
            border-radius: 0;
            border: 0;
            flex: 1;
            @include media-breakpoints-up-lg {
                max-width: 624px;
                flex: unset;
            }
            .login-input-group {
                margin-bottom: 20px;
                position: relative;
                .icon {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    color: #898989;
                    transform: translateY(-50%);
                    font-size: 18px;
                }
            }
            // new added
            .mfnw__form-fixed {
                padding: 20px 15px;
                width: 90%;
                margin:auto;
                @include media-breakpoints-up-md {
                    width: 100%;
                    max-width: 500px;
                    padding-top: 40px;
                }
                @include media-breakpoints-up-lg {
                    margin: auto;
                    max-width: 360px;
                    width: 100%;
                    padding-top: 20px;
                }
                h3 {
                    letter-spacing: 0;
                    margin-bottom: 0;
                }
                .form-check {
                    margin: 0;
                    .form-check-label {
                        font-family: $font-family;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        color: $text-dark;
                    }
                }
                .forgetLink {
                    font-family: $font-family;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    color: $text-dark;
                }
                .btn {
                    &.btnLogin {
                        border-radius: 4px;
                        height: 50px;
                        font-size: 14px;
                        font-weight: 700;
                        color: #fff;
                        @include media-breakpoints-up-md{
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                    }

                }
                .registerLink {
                    font-weight: 700;
                    font-size: 14px;
                    color: #363636;
                    .regLink {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .mfnw-right-footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(188.68deg, #558dd1 0%, #0253b4 100%);
        background: linear-gradient(188.68deg, #639fea 0%, #0253b4 100%);
        height: 40px;
        span {
            font-family: $font-family;
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            text-align: center;
            display: block;
            color: #fff;
        }
        @include media-breakpoints-up-lg {
            display: none;
        }
    }
}
.mobile_login_wrapper{
    height: 100vh;
}
.form_check_secondary {
    .form-check-input:checked {
        background-color: $secondary;
        border-color: $secondary;
    }
}
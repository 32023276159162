@import '@scss/common.scss';

.mfnw__joblisting_content {
    padding: 1rem;
    @include media-breakpoints-down-lg {
        padding: 1.5rem;
    }
    .job_content_right {
        .origin {
            background: $lightpink;
            @include origin-dest-icon;
        }
    }
    .mfnp_overflowbtn {
        white-space: nowrap;
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.job_direction {
    display: flex;
    flex-direction: row;
    @include media-breakpoints-up-lg {
        flex-direction: column;
    }
}
.dropdown_filter-top {
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: 4px;
    height: 45px;
    @include media-breakpoints-up-sm {
        height: unset;
    }
    .btn-default {
        background-color: transparent;
        padding: 0px 1.5rem;
        font-size: 14px;
        &:hover {
            background-color: transparent;
            padding: 0px 1.5rem;
            font-size: 14px;
            &:hover {
                background-color: transparent;
            }
        }
    }
}
// ViewjobPopup

.offer_add_modal {
    @include media-breakpoints-down-lg {
        width: 90%;
    }
    .mfnw_add_job_popup {
        justify-content: start;
        @include media-breakpoints-up-xl {
            justify-content: space-between;
        }
    }
    .mfnw_changestatus_responsive {
        width: 100%;
        padding: 15px 0px;
        @include media-breakpoints-up-lg {
            width: 170px;
            margin-top: unset;
        }
    }
    .mfnw_spacing {
        padding: unset;
    }
    .f_pro_width {
        width: 236px;
    }
    .title-tag {
        font-weight: 600px;
    }
    .icon {
        color: #0270b2;
        font-size: 20px;
    }

    .mfnw-colrespons {
        display: flex;
        justify-content: center;
        @include media-breakpoints-up-md {
            display: flex;
        }
    }
    .storage-details {
        width: 50%;
        font-size: 15px;
        .btn-check {
            width: 200px;
            font-size: 14px;
        }
    }
    .btn-option {
        width: 100px;
        padding: 5px 0px;
        border: 1px solid #ddd;
    }
    .mfnw_offer_radio_btn {
        .mfnw_one {
            @include mfnw_offer_radio_right;
        }
        .mfnw_second {
            @include mfnw_offer_radio_left;
        }
    }
    .mfnw_lasttable_respon {
        width: 50%;
        @include media-breakpoints-up-lg {
            width: 75%;
            flex-direction: column;
        }
    }
    .job_content_right {
        @include media-breakpoints-up-lg {
        }
    }
    .mfnw_btn {
        height: unset;
        border-radius: 4px !important;
        @include media-breakpoints-up-lg {
            justify-content: center;
            border-radius: 12px !important;
        }
    }
}

.btn-unschedule-offer {
    font-size: 12px !important;
    padding: 4px 10px !important;
    height: 30px;
    background-color: #898989 !important;
    border: none !important;

    &:hover {
        background-color: #9d9898 !important;
    }
    @include media-breakpoints-up-xl {
        font-size: 14px !important;
        padding: 15px 20px !important;
        height: unset;
    }
}
.only_direction_responsive {
    display: flex;
    flex-direction: row;
    @include media-breakpoints-up-lg {
        flex-direction: column;
    }
}
.btn_unschedule_job {
    width: 100px;
    font-size: 9px !important;
    @include media-breakpoints-up-lg {
        width: unset;
        font-size: 14px !important;
    }
    @include media-breakpoints-up-md {
        font-size: 12px;
    }
}
.btn-unschedule-offer {
    font-size: 10px !important;
    @include media-breakpoints-up-lg {
        font-size: 14px !important;
    }
}
.mfnw_model_view_detail {
    .modal-content {
        width: 700px;
        z-index: -1 !important;
    }
}
// Add Note Popup
.mfnw_main_division {
    border: 1px solid $input-border-color;
    border-radius: 4px;
    overflow-x: auto;

    .mfnw_accordion {
        .accordion-button::after {
            margin-top: 0px;
        }
    }
    .mfnw_first {
        background-color: $bg-color;
        border-bottom: 1px solid $input-border-color;
        margin: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex;
        flex-wrap: nowrap;
    }
    .mfnw_second {
        margin: 0;
    }

    .document_second_scroll {
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
    }
    .mfnw_third {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $input-border-color;
    }

    .mfnw_accordion {
        padding: 0;
        .mfnw_border {
            border-top: 1px solid $input-border-color;
        }
        .accordion-button {
            align-items: center;
            justify-content: center;
        }
        .accordion-button::after {
            margin-left: 0;
        }
    }
    .mfnw_detail_second {
        height: 440px;
        padding: 20px 18px 20px 17px;
    }
    .mfnw_detail_three {
        border-radius: 12px;
        border: 1px dashed #898989;
        background: #fff;
        align-items: center;
        height: 360px;
    }
    .mfnw_detail_for {
        margin: 30px;
        background-color: #f6f6f6;
        height: 300px;
    }
}
// addDocumentPopup
.mfnw_model_size {
    .modal-dialog {
        max-width: 620px;
    }

    .main-background {
        background-color: #f6f6f6;
        padding: 5px 5px 5px 5px;
        border-radius: 5px;
    }
    .second-background {
        background-color: #e4edf3;
    }
    .form-control-search {
        background: #f6f6f6;
        width: 24px;
        height: 24px;
        color: #363636;
    }
    .mfnw_main_btn {
        .btn-onlyone {
            padding: 15px 0px;
        }
    }
    .mfnw_setfont {
        .mfnw_fontsize {
            font-size: 10px;
        }
    }
}
// schedule
.mfnw_schedule {
    .title-icon {
        .job_content_right {
            .origin {
                background: $lightpink;
                @include origin-dest-icon;
            }
        }
    }
    .mfnw_address {
        width: 65%;
        line-height: 30px;
    }
    .mfnw_address_second {
        width: 70%;
        line-height: 30px;
    }
    .border {
        border-collapse: separate;
        border-spacing: 0;
        border-radius: 4px;
        overflow: hidden;
        .table-headings {
            background-color: #f6f6f6;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
.scedule_responsive__origin {
    flex-wrap: wrap;
    @include media-breakpoints-up-sm {
        flex-wrap: nowrap;
        align-items: start;
    }
}
.note {
    height: 18px;
    width: 180px;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.btn-canceled {
    background-color: #f58b7f !important;
    border-color: #f58b7f !important;
}

.btn-canceled:hover {
    background-color: #f58b7f !important;
}

.btn-sit-delivery {
    background-color: #3399cc !important;
    border-color: #3399cc !important;
}

.btn-sit-delivery:hover {
    background-color: #2b8ab2 !important;
}
.frist_responsiveline {
    @include media-breakpoints-up-xxl {
        display: block;
    }
}
.last_responsiveline {
    display: block;
    width: 65%;
    @include media-breakpoints-up-lg {
        display: none;
        width: 50%;
    }
}

.info-box {
    max-width: 100px;
}
.max-card-height {
    height: 95%;
}
// document page
.mfnw_document_table {
    overflow-x: auto;
    padding: 5px;
    .table {
        border-spacing: 0;
        width: 100%;
        border-radius: 12px;
        box-shadow: 0 0 0 1px #ccc;
    }
    .table th {
        padding: 20px 15px !important;
        background-color: #f6f6f6;
        width: 100%;
        min-width: 150px;
        &:first-child {
            border-top-left-radius: 12px;
        }
        &:last-child {
            border-top-right-radius: 12px;
        }
        @include media-breakpoints-up-lg {
            padding: 20px 30px !important;
            width: 25%;
            min-width: 100%;
        }
    }
    .table .new_table_data {
        padding: 15px 5px !important;
        font-size: 12px;
        @include media-breakpoints-up-sm {
            padding: 20px 12px !important;
            font-size: 14px;
        }
        padding: 20px 5px;
        @include media-breakpoints-up-md {
            padding: 20px 12px !important;
            font-size: 14px;
        }
        @include media-breakpoints-up-lg {
            padding: 24px 30px !important;
        }
    }
    .mfnw_document_table_width {
        width: 30%;
    }
    .btn-default {
        background-color: #fff;
        border: #fff;
        padding: 0;
        margin: 0;
    }
    .btn-default:hover {
        background-color: #fff;
    }

    .mfnw_pensil_icon {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .mfnw_detail_class {
        width: 50%;
        align-self: center;
        padding: 20px 0px 50px 0px !important;
    }
    .mfnw_detail {
        border-radius: 12px;
        background: #f6f6f6;
    }
    .mfnw_detail_second {
        height: 440px;
        padding: 20px 18px 20px 17px;
    }
    .mfnw_detail_three {
        border-radius: 12px;
        border: 1px dashed #898989;
        background: #fff;
        align-items: center;
        height: 360px;
    }
    .mfnw_detail_for {
        margin: 30px;
        background-color: #f6f6f6 !important;
        height: 300px;
    }
}
// note page
.mfnw_note_table {
    overflow-x: auto;
    padding: 5px;

    .table {
        border-spacing: 0;
        width: 100%;
        border-radius: 12px;
        box-shadow: 0 0 0 1px #ccc;
    }
    .table th {
        padding: 20px 30px !important;
        background-color: #f6f6f6;
        &:first-child {
            border-top-left-radius: 12px;
        }
        &:last-child {
            border-top-right-radius: 12px;
        }
    }
    .note_table_width {
        width: 25%;
        @include media-breakpoints-up-lg {
            width: 39% !important;
        }
    }
    .table .new_table_data {
        padding: 15px 5px !important;
        font-size: 12px;
        @include media-breakpoints-up-sm {
            padding: 20px 12px !important;
            font-size: 14px;
        }
        @include media-breakpoints-up-lg {
            padding: 24px 30px !important;
        }
    }
    .table_responsive_iamge {
        width: 15px;
        @include media-breakpoints-up-lg {
            width: unset;
        }
    }
    .mfnw_table_note_description {
        background: #f6f6f6;
        border-radius: 12px;
        width: 646px !important;
    }

    .mfnw_detail_history_table {
        border-radius: 12px;
        background: #f6f6f6;
    }

    .btn-default {
        background-color: #fff;
        border: #fff;
        padding: 0;
        margin: 0;
    }
    .btn-default:hover {
        background-color: #fff;
    }
}
.width-search-input {
    width: auto;
    min-width: 350;
}

// job list document new css for preview
.w-90 {
    width: 90% !important;
}

.jobDocument_preview {
    .mfnw_detail_second {
        height: 830px !important;
    }

    .h-90 {
        height: 90%;
    }

    .mfnw_detail_three {
        height: 750px !important;
    }
    .mfnw_detail_for {
        height: 700px !important;
    }
}

.card-addingborder {
    .h-500 {
        height: 500px;
    }

    .preview-bg-color {
        background-color: #f6f6f6 !important;
    }
}

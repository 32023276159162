@import '@scss/common.scss';

.page-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .content {
        text-align: center;

        .title {
            font-size: 24px;
            color: $primary;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }

        .message {
            font-size: 16px;
            color: $text-light;
        }
    }
}

@import '@scss/common.scss';

.mfnw__main-wrapper {
    .mfnw__sidebar_toggle {
        width: 0;
        position: fixed;
        height: 100%;
        z-index: 2024;
        top: 0;
        right: 0;
        left: 0;
        background: $linear-gradient;
        transition: width 0.5s ease;
        &::after {
            content: '';
            position: absolute;
            bottom: 70px;
            left: 0;
            right: 0;
            background: url('../../assets/img/cover_login.png');
            background-size: 100%;
            z-index: -1;
            width: 75%;
            background-position: center bottom;
            height: 50%;
            margin: 0 auto;
            background-repeat: no-repeat;
        }
        @include media-breakpoints-up-xl {
            width: var(--margin-left);
        }
        .navbar-toggler {
            z-index: 2024;
            margin: 0;
            padding: 5px 4px;
            margin-top: 10px !important;
        }
        .navbar-toggler:focus{
            box-shadow: none;
        }
        .navbar-nav {
            width: 100%;
            font-size: 1rem;
            color: #fff;
            font-weight: 500;
            a {
                margin: 0.25rem 0;
                padding: 0.5rem 0.75rem;
                display: flex;
                color: #fff;
                align-items: center;
                font-weight: 500;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .active {
            background-color: $primary;
            color: white;
        }
        .mfnw_bottom-toggle {
            display: none;
            @include media-breakpoints-up-xl {
                background-color: #22569f;
                padding: 1.25rem;
                width: 100%;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
        .icon_small {
            width: 35px;
            height: 35px;
        }
        .sidebar_logo {
            background-color: #379bcf;
            min-height: 110px;
            display: none;
            &-small {
                display: none;
            }
        }
        .collapse_text {
            a {
                padding-left: 1.75rem;
            }
        }
    }
    .mfnw__wrapper-content {
        margin-left: 0;
        transition: margin 0.5s ease;
        @include media-breakpoints-up-xl {
            margin-left: var(--margin-left);
            height: 100%;
        }
        .mfnw__wrapper-pages {
            padding-top: 130px;
        }
        .mfnw__wrapper-small {
            padding-top: 77px;
        }
    }
    .mfnw__navigation-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1024;
        width: -moz-available;
        width: -webkit-fill-available;
        width: auto;
        transition: all 0.5s ease;
        @include media-breakpoints-up-xl {
            left: 200px;
            z-index: 2024;
        }
        .right_top_img {
            width: 45px;
            height: 45px;
            background: #e8e8e8;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            font-size: 1.25rem;
            font-weight: bold;
        }
        .mfnw__searchbar {
            border-radius: 12px;
            padding: 0.75rem;
        }
        .dropdown-toggle::after {
            content: none;
        }
        .btn-header.btn-primary {
            padding: 1rem;
        }
    }
}

.sidebar_small {
    .mfnw__sidebar_toggle {
        @include media-breakpoints-up-xl {
            width: var(--margin-left-small);
        }
        .text-toggle {
            display: none;
            @include media-breakpoints-up-lg {
                padding: 10px 0px;
            }
        }

        .collapse_text {
            a {
                padding-left: 0.75rem;
            }
        }
        .sidebar_logo {
            &-small {
                display: block;
            }
            &-big {
                display: none;
            }
        }
    }
    .mfnw__wrapper-content {
        margin-left: var(--margin-left-small);
    }
    .mfnw__navigation-top {
        left: var(--margin-left-small);
    }
}
// responsive 
.offcanvas_head{
    background-color: #379bcf;
    min-height: 105px;
@include media-breakpoints-up-xl{
    background-color: #379bcf;
    min-height: 110px;
}
}
.offcanvas_body{
    background-color: #3080C2;
    @include media-breakpoints-up-xl {
        background-color:unset;
    }
     .navbar-nav {
            width: 100%;
            font-size: 1rem;
            color: #fff;
            font-weight: 500;
            a {
               padding: 0px 10px;
                display: flex;
                color: #fff;
                align-items: center;
                font-weight: 500;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .active {
            background-color: $primary;
            color: white;
        }
        .mfnw_bottom-toggle {
            display: none;
            @include media-breakpoints-up-xl {
                background-color: #22569f;
                padding: 1.25rem;
                width: 100%;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 12px !important;
            }
        }
}
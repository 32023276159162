@import '@scss/common.scss';

.mfnw__form-fixed-profile {
    padding: 4rem;
    h3 {
        letter-spacing: 1.5pt;
    }
    .profile_img {
        border-radius: 50%;
        border: 2px solid #e1e1e1;
        height: 120px;
        width: 120px;
        object-fit: cover;
    }
    .label-m-auto {
        border-radius: 50%;
        border: 2px solid #e1e1e1;
        height: 120px;
        width: 120px;
        object-fit: cover;
    }
    .label-w-20 {
        width: 144px;
        margin: auto;
    }
    .profile_text_img {
        width: 120px;
        height: 120px;
        background: #e8e8e8;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        font-size: 3.25rem;
        font-weight: bold;
    }
    .icon-top-profile {
        right: 8px;
        background-color: $primary;
        padding: 6px;
        width: 36px;
        height: 36px;
        border-radius: 50px;
    }
}
.mfnw__form-fixed-popup {
    margin: 0 auto;
    @include media-breakpoints-up-sm {
        width: 315px;
    }
    h3 {
        letter-spacing: 1.5pt;
    }
}

// new profile page
.mfnp_myprofile {
    .profile_text_img {
        background-color: #e1e1e1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        font-size: 3.25rem;
        font-weight: bold;
    }

    .profile_text_img_small {
        background: #e1e1e1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        font-size: 2rem;
        font-weight: bold;
        height: 75px;
        width: 75px;
    }
    .mfnp_maincontainar {
        width: auto;
        height: 300;
        background-color: #fff;
        .mfnp_myprofile_second {
            width: auto;
            // width: 1670px;
            height: 120px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            @include media-breakpoints-up-md {
                height: 150px;
            }
        }
        .mfnp_imagemaintag {
            width: 120px;
            height: 120px;
            background-color: #e1e1e1;
            left: 0px;
            @include media-breakpoints-up-md {
                width: 154px;
                height: 150px;
                left: 110px;
            }
            @include media-breakpoints-up-lg {
                left: 180px;
            }
        }
        .mfnp_myprofile_form {
            max-width: 1260px;
            margin: auto;
            @include media-breakpoints-up-sm {
                max-width: 1666px;
            }
            @include media-breakpoints-up-md {
                max-width: 1666px;
                padding: 100px 80px;
            }
        }
        .mfnw__button_user {
            padding: 8px 32px;
            border-radius: 25px;
            margin: 15px 10px;
        }
        .mfnp_last_button {
            width: 440px;
        }
        .last_btn_bg {
            background-color: #d9d9d9;
            border: none;
            &:active {
                border: none;
                background-color: #e1e1e1;
            }
        }
    }
}

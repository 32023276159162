@import '@scss/common.scss';
.mfnw__stepbar_process {
    background: linear-gradient(90deg, #3aa6de 0%, #2660aa 100%);
    border: 1px solid hsl(0, 0%, 87%);
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    border-radius: 50px;
    .step_content {
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;

        .icon_check {
            width: 24px;
            height: 24px;
            border-radius: 50px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ffffff;
            color: $text-light;
            @include media-breakpoints-up-sm {
                width: 24px;
                height: 24px;
            }
        }
    }
    .complete_step {
        .icon_check {
            background-color: $success;
            color: #fff;
        }
    }
    .current_step {
        .icon_check {
            background-color: $primary;
            color: #fff;
        }
    }
}

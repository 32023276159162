@import '@scss/common.scss';

.mfnw_create_organization {
    .min-vh-80 {
        min-height: 80vh;
    }
    .card {
        min-height: 100%;
        margin-bottom: 1rem;
    }
    .profile_img {
        border-radius: 50%;
        border: 2px solid #e1e1e1;
        height: 120px;
        width: 120px;
        object-fit: cover;
    }
    .profile_text_img {
        width: 120px;
        height: 120px;
        background: #e8e8e8;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        font-size: 3.25rem;
        font-weight: bold;
    }
    .mfnw_org_detail_content {
        font-size: 14px;
        .profile_upload {
            width: 120px;
            height: 120px;
            margin-left: auto;
        }
    }
    .mfnw__employee_bording-card {
        display: flex;
        padding: 1.25rem 0;
        justify-content: space-between;
        border-bottom: 1px solid $input-border-color;
        color: #000;
        p {
            color: $text-light;
            font-size: 0.875rem;
            margin-bottom: 0;
        }
    }
    .btn-check:checked + .btn {
        color: #fff !important;
    }
}
.circle__camera {
    width: 40px;
    height: 40px;
    background-color: $primary;
    bottom: 5px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

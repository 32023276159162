@import '@scss/common.scss';

.mfnw_mainaddress {
    .mfnw_address {
        width: 65%;
        line-height: 30px;
    }
}
.schedule_title_page {
    font-size: 16px;
    @include media-breakpoints-up-lg {
        font-size: 20px;
    }
}
.over_flow_cell {
    overflow-x: auto;
}
.fc-button-active {
    background-color: rgba(2, 112, 178, 0.2) !important;
    border: 1px solid rgba(2, 112, 178, 1) !important;
}
.fc-button-primary:focus {
    box-shadow: none !important;
}
// navbar
.demo-app-main {
    cursor: pointer;
    .fc-toolbar-chunk {
        display: flex;
        align-items: center;
        .fc-new-button {
            background-color: #fff;
            padding: 14px 31px;
            border: 1px solid #ddd;
            font-size: 14px;
            font-weight: 500;
            border-radius: 4px;
            color: black;
        }
        .fc-button-group {
            .fc-prev-button {
                background-color: #f6f6f6;
                border: none;
                color: #ddd;
            }
            .fc-next-button {
                background-color: #f6f6f6;
                border: none;
                color: #ddd;
            }
        }
        .fc-toolbar-title {
            font-size: 15px;
            font-weight: 600;
            @include media-breakpoints-up-xl {
                font-size: 20px;
                font-weight: 700;
                color: #363636;
            }
        }
        .fc-today-button {
            padding: 7px 20px 7px 20px;
            width: 100px;
            text-align: center;
            background-color: #fff;
            border: 1px solid #ddd;
            font-size: 12px;
            border-radius: 4px;
            color: black;
            cursor: pointer;
            font-weight: bold;
            line-height: normal;
            @include media-breakpoints-up-xl {
                padding: 10px 24px 10px 25px;
                width: 100px;
                background-color: #fff;
                border: 1px solid #ddd;
                font-size: 14px;
                font-weight: 500;
                border-radius: 4px;
                color: black;
                cursor: pointer;
                line-height: normal;
            }
        }
    }
    .fc .fc-toolbar {
        align-items: center;
        display: block;
        .fc-button-group {
            .fc-dayGridMonth-button {
                padding: 7px 15px 7px 15px;
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 700;
                color: black;
                margin: 8px 0px 8px 12px;
                width: 100px;
                @include media-breakpoints-up-lg {
                    width: 100px;
                    padding: 10px 24px 10px 25px;
                    background-color: #fff;
                    color: black;
                    margin-right: 25px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            .fc-customWeekView-button {
                padding: 7px 15px 7px 15px;
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 700;
                color: black;
                margin: 8px 0px 8px 0px;
                width: 100px;
                @include media-breakpoints-up-lg {
                    width: 100px;
                    padding: 10px 24px 10px 25px;
                    background-color: #fff;
                    color: black;
                    margin-right: 25px;
                    border: 1px solid #ddd;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 4px;
                    line-height: normal;
                }
            }
            .fc-timeGridDay-button {
                padding: 7px 15px 7px 15px;
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 700;
                color: black;
                margin: 8px 0px 8px 0px;
                width: 100px;
                @include media-breakpoints-up-lg {
                    width: 100px;
                    padding: 10px 24px 10px 25px;
                    background-color: #fff;
                    color: black;
                    margin-right: 25px;
                    font-size: 14px;
                    border: 1px solid #ddd;
                    font-weight: 500;
                    border-radius: 4px;
                    line-height: normal;
                }
            }
            .fc-today-button:active {
                background-color: rgba(2, 112, 178, 0.2);
                border: 1px solid rgba(2, 112, 178, 1);
            }
        }
    }
    .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: unset;
    }
}
.schedule_header_center {
    display: flex;
    justify-content: center;
    @include media-breakpoints-up-lg {
        justify-content: start;
    }
}
// monthly section
.fc_spacing_monthly {
    padding: 15px 0px;
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
}
.event_class {
    display: flex;
    width: 100%;
    .event_one {
        padding: 4px 6px 0px 6px;
        background-color: #e97c54;
        color: #fff;
        border: 0.5px solid #e97c54;
    }
    .event_second {
        border: 0.5px solid #e97c54;
        padding: 4px 30px 0px 15px;
        background-color: #f2c5b4;
        color: black;
        font-size: 14px;
        font-weight: 600;
    }
}
.row_last {
    @include media-breakpoints-up-sm {
        margin: 0px 10px;
    }
    .responsive_right_side {
        margin-right: 4px;
        @include media-breakpoints-up-sm {
            margin-left: 0px;
        }
    }
    .event_font {
        font-size: 12px;
        font-weight: 500;
        color: #363636;
        margin-left: 2px;
        margin-right: 4px;
        @include media-breakpoints-up-sm {
            font-size: 14px;
            font-weight: 700;
            color: #363636;
            margin-left: 10px;
            margin-right: 30px;
        }
    }
    .event_orange {
        width: 18px;
        height: 18px;
        background-color: #ffcb00;
        border-radius: 50%;
    }
    .event_green {
        width: 18px;
        height: 18px;
        background-color: #00c875;
        border-radius: 50%;
    }
    .event_holiday {
        width: 18px;
        height: 18px;
        background-color: #8d989e;
        border-radius: 50%;
    }
    .event_blue {
        width: 18px;
        height: 18px;
        background-color: #0270b2;
        border-radius: 50%;
    }
    .event_red {
        width: 18px;
        height: 18px;
        background-color: #e97c54;
        border-radius: 50%;
    }
    .event_light_blue {
        width: 18px;
        height: 18px;
        background-color: #3399cc;
        border-radius: 50%;
    }
}

.fc-view-harness {
    background-color: white;
}

.event_class {
    display: flex;
    width: 100%;
    .event_one {
        padding: 4px 6px 0px 6px;
        background-color: #e97c54;
        color: #fff;
        border: 0.5px solid #e97c54;
    }
    .event_second {
        border: 0.5px solid #e97c54;
        padding: 4px 30px 0px 15px;
        background-color: rgba(233, 124, 84, 0.15);
        color: black;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
    }
    .event_schedule_one {
        padding: 4px 6px 0px 6px;
        background-color: #0270b2;
        color: #fff;
        border: 1px solid #0270b2;
    }
    .event_schedule_second {
        border: 0.5px solid #0270b2;
        padding: 4px 30px 0px 15px;
        background-color: rgba(2, 112, 178, 0.15);
        color: black;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: unset;
    }
    .event_complete_one {
        padding: 4px 6px 0px 6px;
        background-color: #00b469;
        color: #fff;
        border: 1px solid #00b469;
    }
    .event_complete_second {
        border: 0.5px solid #00b469;
        padding: 4px 30px 0px 15px;
        background-color: rgba(0, 200, 117, 0.15);
        color: black;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: unset;
    }
    .event_assign_one {
        background-color: #ffcb00;
        color: #fff;
        border: 1px solid #ffcb00;
        border-radius: unset;
    }
    .event_assign_second {
        border: 0.5px solid #ffcb00;
        background-color: rgba(255, 203, 0, 0.15);
        color: black;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: unset;
        width: 100%;
    }
}

.fc .fc-timegrid-axis-frame {
    align-items: flex-start !important;
    display: flex;
    justify-content: flex-end;
    overflow: inherit;
}

.event_box_list {
    width: 229px;
    height: 84px;
    background-color: rgba(2, 112, 178, 0.15);
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .event_box_border {
        border: 1px solid #0270b2;
        border-radius: 4px !important;
    }
    .event_box_font {
        font-weight: 600;
    }
}
.packer_element_box {
    border-radius: 4px;
    border: 1px solid #0270b2;
    background: #0270b2;
}

.event_box_list_completed {
    width: 229px;
    height: 84px;
    background-color: #a9eed1;
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .event_box_border {
        border: 1px solid #00b469;
        border-radius: 4px !important;
    }
    .event_box_font {
        font-weight: 600;
    }
}
.packer_element_box_completed {
    border-radius: 4px;
    border: 1px solid #00b469;
    background: #00c875;
}

.packer_element_box_second {
    border-radius: 4px;
    border: 1px solid #eebd00;
    background: #ffcb00;
}

.event_box_second {
    width: 229px;
    height: 84px;
    overflow: hidden;
    background: rgba(255, 203, 0, 0.15);
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .bg-event_second_border {
        border: 1px solid #eebd00;
        border-radius: 4px !important;
        background-color: #ffcb00;
    }
}
// new schedule
.event_box_background {
    background-color: rgba(2, 112, 178, 0.5);
    .accordion-button {
        color: white;
        font-size: 16px;
        font-weight: 600;
    }
    .accordion-button:not(.collapsed) {
        color: white !important;
    }
    .event_boxaccodion {
        width: 50px;
    }
    .element_boxaccordian {
        width: 150px;
    }
}

.element_box_one {
    height: 84px;
    color: black;
    border-bottom: 0.5px solid #b7b7b7;
    border-left: 0.5px solid #b7b7b7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: flex;
    align-items: center;
    .element_box_icon {
        width: 40px;
        height: 40px;
    }
    .element_box_image {
        width: 18.375px;
        height: 21px;
    }
    .element_detailoverflow {
        width: 100px;
        overflow: hidden;
        text-align: left;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.element_box__two {
    width: 13%;
    height: 84px;
    overflow: hidden;
    border-right: 0.5px solid #b7b7b7;
    border-bottom: 0.5px solid #b7b7b7;
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .badge_bg_color {
        background-color: #00c875 !important;
    }
    .badge_bg_second {
        background-color: #ffcb00 !important;
    }
}
.element_two {
    background-color: rgba(0, 200, 117, 0.15);
}
.element_three {
    background-color: rgba(255, 203, 0, 0.15);
}
.element_line {
    color: white;
    border-bottom: 2px solid white;
}
.main-par {
    .dropdown-block {
        width: 54px;
        height: 54px;
        background-color: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form-control-search {
        top: 12px;
        left: 14px;
        width: 28px;
        height: 28px;
    }
}

.fc-col-header-cell-cushion {
    width: 100%;
}

.fc-customWeekView-view th {
    border-bottom: none;
}
.main-search-input {
    .mfnw__search-form-control-bg {
        background-color: #fff;
        border: 0;
        border-radius: 12px;
        color: $text-light;
        min-width: unset;
        width: 200px;
        padding: 15px 15px 14px 55px !important;
    }
    .filter-form-control-bg {
        background-color: #fff;
        border: 0;
        border-radius: 12px;
        color: $text-light;
        min-width: unset;
        width: auto;
        height: 100%;
        font-size: 22px;
    }
    .dropdown_filter-top {
        padding-left: 16px !important;
    }
}
.fc-col-header-cell-cushion {
    text-decoration: none !important;
}

.custom-cell {
    min-height: 300px;
}

.fc .fc-daygrid-event {
    margin-bottom: 10px !important;
}
.month-view {
    .fc .fc-daygrid-day-frame {
        min-height: 90px;
        position: relative;
    }
}
.week-view {
    .fc .fc-daygrid-day-frame {
        display: flex;
        flex-direction: column-reverse; /* This reverses the order of child elements vertically */
        height: 100%;
    }
    .fc-timegrid-axis {
        vertical-align: middle !important;
    }
    .fc-col-header th {
        vertical-align: bottom;
    }

    colgroup col {
        display: none;
    }
}
.job .week-view th.fc-timegrid-axis:after {
    vertical-align: bottom;
    content: 'Categories';
}

.packer .week-view th.fc-timegrid-axis:after {
    vertical-align: bottom;
    content: 'Employees';
}
.driver .week-view th.fc-timegrid-axis:after {
    vertical-align: bottom;
    content: 'Employees';
}

.mfnw__calander_indexing {
    border-bottom: transparent;

    .nav-item {
        // padding: 0.75rem 0;
        .nav-link {
            color: #363636;
            position: relative;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            padding: 0px 30px;
            border: none;
            &:focus {
                border: none;
            }
            &.active {
                border: none;
                background-color: transparent;
                color: $primary;
                &::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background-color: $primary;
                    width: 100%;
                    left: 0;
                    bottom: -2px;
                }
            }
        }
    }
}

.mfnw__calander_indexing .nav-item .nav-link {
    color: #363636;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 5px 30px;
    border: none;
}
.fc-daygrid-day-number {
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
}

.element_box_footer {
    border-radius: 10px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 4px 6px;
    justify-content: center;
}
.element_box_footer_last {
    border-radius: 10px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 6px 9px;
    justify-content: center;
}

.job_info_modal {
    background-color: #ddd !important;
    color: #363636;
}
// driver element
.driver_element {
    .table > :not(caption) > * > * {
        text-align: center;
        width: 66px;
        padding: 8px 7px;
        background-color: #fff !important;
    }
    .driver-box-collapse {
        padding: 0px !important;
    }
    .driver_element_header_width {
        width: 200px !important;
        font-size: 20px !important;
        color: #898989 !important;
        vertical-align: middle;
        text-align: center;
    }
    .driver_element_font_width {
        font-size: 14px;
        font-weight: 600px;
        line-height: normal;
        color: #363636;
        vertical-align: middle;
        text-align: center;
    }
}
.driver_element {
    .driver_element_spacing {
        padding: 0px 20px !important;
        .element_box_icon {
            width: 40px;
            height: 40px;
            border: 1px solid #0270b2;
        }
        .element_detailoverflow {
            text-align: left;
            width: 100px;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .driver_element_user {
        .driver_element_spacing {
            padding: 0px 20px !important;
            .element_box_icon {
                width: 40px;
                height: 40px;
            }
            .element_detailoverflow {
                width: 100px;
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .driver_element_bg {
        background-color: rgba(2, 112, 178, 0.15);
    }
    .driver_box_content {
        background-color: rgba(2, 112, 178, 0.15);
        padding: 15px 10px;
    }

    .driver_packer_box {
        border-radius: 4px;
        border: 1px solid #0270b2;
        background: #0270b2;
        width: 98px;
    }

    .driver_packer_box_completed {
        border-radius: 4px;
        border: 1px solid #00b469;
        background: #00c875;
        width: 102px;
    }
    .driver_box_content_completed {
        background-color: rgba(0, 200, 117, 0.15);
        padding: 15px 10px;
    }

    .driver_packer_box_assign {
        border-radius: 4px;
        border: 1px solid #eebd00;
        background: #ffcb00;
        width: 98px;
    }
    .driver_box_content_assign {
        background-color: rgba(255, 203, 0, 0.15);
        padding: 15px 10px;
    }
}
// close element
.element_close_button {
    width: 229px;
    height: 84px;
    border: 0.5px solid #b7b7b7;
    background-color: #ddd;
    padding: 12px 12px 50px 12px;
    .element_close_badge {
        width: 77px;
        justify-content: center;
        border-radius: 4px;
        border: 0.5px solid #898989;
        background: #b7b7b7;
        padding: 0px 3px;
    }
}
.packer {
    .day-view {
        .fc-timeGridDay-view {
            display: none;
        }
    }
}
.driver {
    .day-view {
        .fc-timeGridDay-view {
            display: none;
        }
    }
}
.day-view {
    .react-datepicker-wrapper {
        width: 120px;
        z-index: 1;
        .mfnw__login-form-control-bg {
            background-color: #ffffff;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .react-datepicker-popper {
        z-index: 1000;
    }
    .input-group-text {
        background-color: #ffffff !important;
    }
    .date-input-poison {
        position: absolute;
        top: -6px;
        left: 162px;
    }
    .fc-customRangeButton-button {
        width: 188px;
        visibility: hidden;
    }
}
.packer .week-view {
    .react-datepicker-wrapper {
        width: 220px;
        z-index: 1;
        .mfnw__login-form-control-bg {
            background-color: #ffffff;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .react-datepicker-popper {
        z-index: 1000;
    }
    .input-group-text {
        background-color: #ffffff !important;
    }
    .date-input-poison {
        position: absolute;
        top: 175px;
        left: 380px;
    }
    .fc-customRangeButton-button {
        width: 285px;
        visibility: hidden;
    }
}
.packer__dropdown {
    position: unset !important;
}
.demo-app-main {
    @media only screen and (min-width: 1920px) {
        .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
            min-height: calc(100vh - 280px);
        }
        .fc-dayGridMonth-view,
        .fc-scrollgrid,
        .fc-scroller-harness,
        .fc-scroller,
        .fc-daygrid-body,
        .fc-scrollgrid-section {
            height: 100%;
        }

        .fc .fc-scrollgrid-section,
        .fc .fc-scrollgrid-section table,
        .fc .fc-scrollgrid-section > td {
            height: 100%;
        }
    }
    &.week-view {
        @media only screen and (min-width: 1920px) {
            .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
                height: auto;
            }
            .fc-dayGridMonth-view,
            .fc-scrollgrid,
            .fc-scroller-harness,
            .fc-scroller,
            .fc-daygrid-body,
            .fc-scrollgrid-section {
                height: auto;
            }

            .fc .fc-scrollgrid-section,
            .fc .fc-scrollgrid-section table,
            .fc .fc-scrollgrid-section > td {
                height: auto;
            }
        }
    }
}
.packer {
    .day-view {
        .fc-media-screen {
            display: none;
        }
    }
}
.driver {
    .day-view {
        .fc-media-screen {
            display: none;
        }
    }
}

@mixin media-breakpoints-up-sm {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin media-breakpoints-up-md {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin media-breakpoints-up-lg {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin media-breakpoints-up-xl {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin media-breakpoints-up-xxl {
    @media (min-width: 1400px) {
        @content;
    }
}

@mixin media-breakpoints-down-sm {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin media-breakpoints-down-md {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin media-breakpoints-down-lg {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin media-breakpoints-down-xl {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin media-breakpoints-down-xxl {
    @media (max-width: 1400px) {
        @content;
    }
}
@mixin origin-dest-icon {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: $white;
}
@mixin table-boxborder {
    border: 1px solid #dddddd;
    padding: 0px 16px;
    border-radius: 4px;
}
@mixin mfnw_offer_radio_right {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
@mixin mfnw_offer_radio_left {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

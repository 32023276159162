@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/Avenir-Light.woff2') format('woff2'),
        url('../../fonts/Avenir-Light.woff') format('woff');
    font-weight: 200;
    font-style: light;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/Avenir-Book.woff2') format('woff2'),
        url('../../fonts/Avenir-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/Avenir-Medium.woff2') format('woff2'),
        url('../../fonts/Avenir-Medium.woff') format('woff');
    font-weight: 400;
    font-style: medium;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/Avenir-Heavy.woff2') format('woff2'),
        url('../../fonts/Avenir-Heavy.woff') format('woff');
    font-weight: 600;
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/Avenir-Black.woff2') format('woff2'),
        url('../../fonts/Avenir-Black.woff') format('woff');
    font-weight: 800;
    font-style: bolder;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Thin.woff2') format('woff2'),
        url('../../fonts/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Light.woff2') format('woff2'),
        url('../../fonts/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../../fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../../fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../../fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Black.woff2') format('woff2'),
        url('../../fonts/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@import '@scss/common.scss';

.bg-icon_radio_state {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        background: url('/assets/img/state-map.svg');
        height: 50px;
        top: 8px;
        right: 10px;
        width: 90px;
        background-repeat: no-repeat;
    }
}
.bg-icon_radio_city {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        background: url('/assets/img/city.svg');
        height: 50px;
        top: 5px;
        right: 5px;
        width: 60px;
        background-repeat: no-repeat;
    }
}
.profile_pic_upload {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        border: 2px dashed $primary;
        width: 130px;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        height: 130px;
        border-radius: 100%;
    }
    img {
        object-fit: cover;
        width: 100%;
    }
}
.main-par{
    .dropdown-block {
        width: 54px;
        height: 54px;
        background-color: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form-control-serch {
        top: 17px;
        right: 14px;
    }
}

@import 'common.scss';
@import 'fonts.scss';
@import '~bootstrap/scss/bootstrap';

:root {
    --bs-border-color: #dddddd;
    --margin-left: 200px;
    --margin-left-small: 60px;
    --bs-btn-active-color: $white;
}

html,
body {
    background-color: $bg-color;
    color: $text-dark;
    font-family: $font-family;
    font-weight: 400;
    margin: 0;
    padding: 0 !important;
    height: 100%;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
}

.text-dark {
    color: $text-dark;
}

.form-label {
    font-size: 0.75rem;
    font-weight: 600;
}

a {
    text-decoration: none;
    color: $text-dark;
    font-weight: 600;
}

.border-green-600 {
    border-color: #00ae8b !important;
    box-shadow: none !important;
}

.text-gray {
    color: #777;
}
.mfnw__button_small {
    min-width: 100px;
    padding: 0.5rem;
    font-size: 0.875rem;
    border-radius: 50px;
    @include media-breakpoints-up-lg {
        min-width: 150px;
    }
}
// step one
.btn-default {
    background: #d9d9d9;
    color: #363636;
    &:hover {
        background-color: #d9d9d4;
    }
}
.offcanvas {
    z-index: 2024;
}

.cursor-pointer {
    cursor: pointer;
}
.text-sm {
    font-size: 12px;
}
.text-base {
    font-size: 14px;
}
.text-pink {
    color: $pink;
}
.text-lightpink {
    color: $lightpink;
}
.invalid-msg {
    color: #ff0000;
    font-size: 13px;
    margin-top: 5px;
}
.input-group {
    flex-wrap: nowrap;
}

.side_settings {
    padding: 0.5rem 0.75rem;
}
.fs-18 {
    font-size: 18px;
}

.css-b62m3t-container {
    width: 100%;
}
.mfnw__input-group {
    min-width: 100px;
    display: flex;
    font-size: 14px;
    padding: 0.875rem;
    justify-content: center;
    align-items: center;
}
.mfnw__input-date-group {
    background-color: #ddd !important;
    color: #363636;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    font-size: 1rem;
    font-weight: 400;
    border: none;
    line-height: 1.5;
    color: #898989;
    text-align: center;
    white-space: nowrap;
    height: 45px;

    @include media-breakpoints-up-md {
        height: 54px;
    }
}
.mfnw__input-to-date-group {
    display: flex;
    background-color: #fff;
    font-size: 13px;
    border: none;
    border-radius: 0px;
    padding: 0.875rem;
    justify-content: center;
    align-items: center;
}

.mfnw__login-form-control {
    // background: transparent;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0.875rem;
    // padding-right: 32px;
    width: 100%;
    font-size: 14px;
    border: 1px solid #dddddd;
}
.mfnw__login-form-control-bg {
    background-color: $bg-color;
}
.input-group {
    .select_control_single__control {
        border-radius: 6px;
        height: 100%;
        min-height: unset;
        padding: 0.415rem;
        background-color: $bg-color;
        border: 1px solid #dddddd;
    }
    .select_control_group__control {
        border-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        height: 100%;
        min-height: unset;
        background-color: $bg-color;
        border: 1px solid #dddddd;
    }
    .mfnw__borderless_select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .mfnw__borderless_left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
.min-w-120 {
    min-width: 122px;
}
.mnfw_border-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.mfnw__search-form-control-bg {
    background-color: #fff;
    border: 0;
    border-radius: 12px;
    color: $text-light;
    min-width: 200px;
    padding: 1.02rem;
}
.mfnw__date-from-form-control-bg {
    background-color: #fff;
    height: 54px;
    border-radius: 0px;
    border: none;
    margin-right: 1px;
    width: 126px;
}
.mfnw__date-to-form-control-bg {
    background-color: #fff;
    height: 45px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
    padding: 6px;
    width: 200px;
    @include media-breakpoints-up-md {
        height: 54px;
    }
}
.mfnw__year-control-bg {
    background-color: #fff;
    height: 45px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
    padding: 6px;
    width: 100px;
    @include media-breakpoints-up-md {
        height: 54px;
    }
}
.btn-cancel {
    background-color: $lightpink;
    color: $white;
    border-color: $lightpink;
    &:hover {
        background-color: #ec7568 !important;
        color: $white;
        border-color: #ec7568;
    }
    &:focus {
        background-color: #ec7568 !important;
        color: $white;
        border-color: #ec7568;
    }
}
.btn__radio-success {
    color: $text-dark;
    font-size: 14px;
    padding: 0.5rem 1rem;
    min-width: 80px;
    border: 1px solid $input-border-color;
    margin: 0;
    background-color: $bg-color;
}
.min-h-56 {
    min-height: 56px;
}
.btn-close {
    background-color: #f6f6f6;
    font-size: 12px;
}
.mnfw__badge_light {
    width: fit-content;
    // min-width: 150px;
    margin-right: 1rem;
    font-size: 14px;
    margin-bottom: 1rem;
}
.custom_radio_button {
    border-radius: 12px;
    border: 1px solid #ddd;
    background: linear-gradient(90deg, #f1f1f1 0%, #ddd 100%);
    width: 100%;
    min-width: unset;
    padding: 1.25rem 1rem;
    transition: all 0.1s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @include media-breakpoints-up-md {
        max-width: 210px;
        min-width: 210px;
    }
    .form-check-input {
        margin-left: 0;
        cursor: pointer;
        &:checked {
            background-color: $white;
            border-color: $white;
        }
    }
    .form-check {
        font-size: 14px;
        padding-left: 0;
        margin-bottom: 0;
        min-height: auto;
        &-label {
            margin-left: 0.5rem;
        }
    }
    &.checked {
        background: linear-gradient(90deg, #3aa6de 0%, #2660aa 100%);
        color: $white;
    }
}
// tab design

.mfnw__dashboard_linetab {
    border-bottom: transparent;
    gap: 1.5rem;
    // .nav-link{
    //     padding: 4px 22px !important;
    //     @include media-breakpoints-up-sm{
    //         padding: 4px 30px !important;
    //     }
    //  }
    .nav-item {
        // padding: 0.75rem 0;
        .nav-link {
            color: #898989;
            position: relative;
            text-transform: uppercase;
            padding: 0;
            border: none;
            &:focus {
                border: none;
            }
            &.active {
                border: none;
                background-color: transparent;
                color: $primary;
                &::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background-color: $primary;
                    width: 100%;
                    left: 0;
                    bottom: -3px;
                }
            }
        }
    }
}
.mfnw__calander_indexing {
    z-index: 10;
}
.mfnw__left_content {
    display: flex;
    align-items: center;
    .mfnw__circle-logo {
        width: 100px;
        height: 100px;
        background: #f6f6f6;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem;
        margin-right: 1rem;
        @include media-breakpoints-down-sm {
            width: 90px;
            height: 90px;
        }
    }
    .mfnw__right-content {
        font-size: 14px;
        @include media-breakpoints-down-sm {
            display: flex;
            flex-direction: column;
        }
        img {
            vertical-align: sub;
        }
        .cp_name {
            margin: 0;
            font-weight: 600;
        }
        .address {
            margin: 0;
        }
        .address-text {
            margin: 0;
            color: $text-light;
        }
    }
}
.mfnp_user_site {
    @include media-breakpoints-down-sm {
        width: 90px;
        height: 60px;
    }
}
.right_content {
    color: #b7b7b7;
}
.dropdown {
    .btn-check:checked + .btn,
    :not(.btn-check) + .btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
        background-color: transparent;
        border-color: transparent;
    }
    .dropdown-menu {
        box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.15);
    }
}
.h-fit {
    height: fit-content;
}
.btn-header.btn-primary {
    background: none;
    border: none;
    padding: 0;
    &:hover {
        background: none;
        border: none;
    }
}
.dropdown-toggle::after {
    content: unset;
}

.text-iconcolor {
    color: $iconcolor;
}
.profile_bg_initial {
    width: 100px;
    height: 100px;
    background-color: $bg-color;
    border-radius: 100%;
    color: $text-light;
    font-weight: 700;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
.bg-pink {
    background-color: $pink;
    color: $white;
}
.border-pink {
    border-color: $pink !important;
}
.btn_type_large {
    border-radius: 12px;
    @include media-breakpoints-up-xl {
        min-width: 200px;
    }
}

.accordion-button::after {
    margin-top: 0.5rem;
}
.icon_right_button {
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}
.button__details {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    & :focus {
        background-color: #2660aa !important;
        color: $white;
    }
}
.spaced_text {
    letter-spacing: 1rem;
    text-align: center;
}
.dashed_border_div {
    border: 1px dashed $input-group-addon-border-color;
}
.bg-disable {
    background-color: #d3d3d3 !important;
}
.bg-active {
    background-color: #b7b7b7 !important;
}
.bg-unactive {
    background-color: #f6f6f6 !important;
}

.title_common_main {
    display: flex;
    align-items: center;

    h4 {
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        padding-right: 7px;
        margin-bottom: 0px;
        @include media-breakpoints-down-md {
            font-size: 16px;
        }
    }
    .badge {
        background-color: $primary !important;
        padding: 6px 8px;
        border-radius: 14px;
        color: #fff;
    }
}
.mfnw_navrespons {
    @include media-breakpoints-down-sm {
        display: flex;
        flex-direction: column;
    }
    .scndblock {
        @include media-breakpoints-down-sm {
            padding-top: 5px;
        }
    }
}
.swal2-container {
    z-index: 3060 !important;
}

.pagination {
    float: right;
}
.row-odd {
    background: #f1f1f1;
}
// temp css
.page-link.active,
.active > .page-link {
    z-index: 0 !important;
}

.event_one {
    padding: 4px 4px 4px 5px;
    background-color: #e97c54;
    color: #fff;
    border: 1px solid #e16e44;
}
.event_second {
    border: 0.5px solid #e97c54;
    background-color: rgba(233, 124, 84, 0.15);
    color: black;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event_complete_one {
    padding: 4px 4px 4px 5px;
    background-color: #00b469;
    color: #fff;
    border: 1px solid #00b469;
}
.event_complete_second {
    border: 0.5px solid #00b469;
    background-color: rgba(0, 200, 117, 0.15);
    color: black;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.event_schedule_one {
    padding: 4px 4px 4px 5px;
    background-color: #0270b2;
    color: #fff;
    border: 1px solid #0270b2;
}
.event_schedule_second {
    border: 0.5px solid #0270b2;
    background-color: rgba(2, 112, 178, 0.15);
    color: black;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.event_holiday_one {
    padding: 4px 4px 4px 5px;
    background-color: #8d989e;
    color: #fff;
    border: 1px solid #8d989e;
}
.event_holiday_second {
    border: 0.5px solid #8d989e;
    background-color: rgba(140, 144, 146, 0.15);
    color: black;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event_assign_one {
    padding: 4px 4px 4px 5px;
    background-color: #ffcb00;
    color: #fff;
    border: 1px solid #ffcb00;
}
.event_assign_second {
    border: 0.5px solid #ffcb00;
    background-color: rgba(255, 203, 0, 0.15);
    color: black;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event_sit_delivery_one {
    padding: 4px 4px 4px 5px;
    background-color: #3399cc;
    color: #fff;
    border: 1px solid #3399cc;
}
.event_sit_delivery_second {
    border: 0.5px solid #3399cc;
    background-color: rgba(51, 153, 204, 0.15);
    color: black;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fc-h-event {
    border: 0.5px #e97c54;
    margin: 5px 0px 5px 0px;
}
.mfnw_bg {
    background: #d3d3d3;
}

.page-loader {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    right: 0;
    z-index: 3020;
    color: white;
}
.main-searchInput input {
    height: 45px;
    @include media-breakpoints-up-md {
        height: unset;
    }
}

.main-par {
    .dropdown-block {
        width: 54px;
        height: 54px;
        background-color: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form-control-serch {
        top: 13px;
        right: 14px;
        @include media-breakpoints-up-md {
            top: 17px;
            right: 14px;
        }
    }
}
.badge_custom {
    font-size: 0.775rem;

    @include media-breakpoints-up-lg {
        font-size: 1rem;
    }
}
.radio_custom {
    font-size: 0.775rem;
    min-width: 145px;
    @include media-breakpoints-up-lg {
        font-size: 1rem;
        min-width: 210px;
    }
}
.overflow-table-auto {
    overflow-x: auto;
    @include media-breakpoints-up-xl {
        max-width: calc(98vw - var(--margin-left));
        overflow-x: auto;
    }
}
.overflow-offer-table-auto {
    overflow-x: auto;
    @include media-breakpoints-up-xl {
        max-width: calc(93vw - var(--margin-left));
        overflow-x: auto;
    }
}

.tooltip {
    // display: block !important;
    visibility: visible !important;
    z-index: 1050000 !important;
    width: auto !important;
}
.custom-tooltip {
    max-width: none !important; /* Allow the tooltip to grow wider */
    width: 300px !important; /* Set the desired width */
}
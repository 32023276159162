@import '@scss/common.scss';

.mfnw__dashboard-content {
    padding: 1rem;
    @include media-breakpoints-up-lg {
        padding: 1.5rem;
    }

    .mfnw__dashboar-card {
        padding: 1rem;
        border-radius: 12px;

        p,
        h1 {
            color: #fff;
        }

        h1 {
            margin-top: 0.5rem;
            margin-bottom: 0;
        }

        .icon {
            right: 1rem;

            @include media-breakpoints-up-lg {
                right: 2rem;
            }
        }
    }

    .mfnw__card-box-grid {
        margin-bottom: 1rem;
        border: 0;
        border-radius: 12px;
        .mfnw__content-center {
            border-top: 0.125em dashed #898989;
            border-bottom: 0.125em dashed #898989;
            border-spacing: 0.25em;
            margin-top: 1rem;
            font-size: 12px;

            label {
                color: #898989;
            }
        }

        .mfnw_top_card_content {
            span {
                font-size: 0.875rem;
            }
        }

        .row_bottom {
            font-size: 12px;
            margin-top: 1rem;

            span {
                color: #898989;
            }
        }
    }

    .mfnw_column_tag {
        flex: 0 0 auto;
        @include media-breakpoints-up-md {
            width: 50%;
        }

        @include media-breakpoints-up-lg {
            width: 32%;
        }

        @include media-breakpoints-up-xxl {
            width: 25%;
        }
        .mfnw__offered-card {
            margin-top: 1rem;
            padding: 1.5rem;
            border-radius: 12px;
            border-left: 12px solid;
            border-top: 0px;
            border-right: 0px;
            border-bottom: 0px;

            .card_icon {
                right: 15px;
                opacity: 0.2;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .mfnw_column_bottom_tag {
        flex: 0 0 auto;
        @include media-breakpoints-up-md {
            width: 50%;
        }

        @include media-breakpoints-up-lg {
            width: 50%;
        }
        @include media-breakpoints-up-xl {
            width: 50%;
        }

        @include media-breakpoints-up-xxl {
            width: 20%;
        }
        .mfnw__offered-card {
            margin-top: 1rem;
            padding: 1.5rem;
            border-radius: 12px;
            border-left: 12px solid;
            border-top: 0px;
            border-right: 0px;
            border-bottom: 0px;

            .card_icon {
                right: 15px;
                opacity: 0.2;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .responsive_dashboard_firstcard {
        img {
            width: 50px;
            height: 50px;
            @include media-breakpoints-up-xxl {
                width: unset;
                height: unset;
            }
        }
    }
    .empInfo {
        font-size: 14px;
        color: $text-light;
        line-height: 20px;
        margin-bottom: 0;
    }
    .mfnw__employee_bording {
        .mfnw__employee_bording-card {
            display: flex;
            padding: 1.25rem 0;
            justify-content: space-between;
            border-bottom: 1px solid $input-border-color;
            color: #000;
            p {
                color: $text-light;
                font-size: 0.875rem;
                margin-bottom: 0;
            }
        }
    }

    .orgInfo {
        font-size: 14px;
        color: $text-light;
    }
}

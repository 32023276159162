@import '@scss/common.scss';

.mfnw__form-fixed {
    margin: 0 auto;

    h3 {
        letter-spacing: 1.5pt;
    }
}

.verifyDiv {
    .inputStyle {
        padding: 1rem 0rem;
        margin: 0 auto;
        width: 3rem !important;
        border: 1px solid #ddd;
        border-radius: 6px;
    }
    .otpElements {
        max-width: 340px;
        margin: 0 auto;
        .otp > div {
            justify-content: space-around;
        }
    }
}

@import '@scss/common.scss';

.top-common-title {
    justify-content: space-between;
    @include media-breakpoints-up-md {
        justify-content: center;
        position: relative;
    }
    .title_common_main {
        display: flex;
        align-items: center;

        h4 {
            font-size: 18px;
            color: #000000;
            text-transform: uppercase;
            padding-right: 7px;
            margin-bottom: 0px;
            @include media-breakpoints-up-md {
                font-size: 16px;
            }
        }
        .badge {
            background-color: $primary !important;
            padding: 6px 8px;
            border-radius: 14px;
            color: #fff;
        }
    }
}

.dropdown-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.list-grid-title {
    .list-grid-title-main {
        background-color: #fff;
        width: 52px;
        align-items: center;
        justify-content: center;
        height: 52px;
        border-radius: 6px;
        display: inline-flex;
        cursor: pointer;
        margin: 0px 10px;
    }
    i {
        font-size: 30px;
        line-height: 30px;
        color: #898989;
    }
    .only-one-offer {
        display: flex;
        width: 100px;
        height: 30px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
    }
}
// new
.mfpro-info {
    .first-part {
        width: 20%;
    }
    .form-control-serch1 {
        display: flex;
        @include origin-dest-icon;
        .icon {
            font-size: 20px;
        }
    }
    .address {
        color: #363636;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .mfnw-respons-image {
        img {
            width: 300px;
            height: 300px;
            @include media-breakpoints-up-lg {
                width: unset;
                height: unset;
            }
        }
    }
    .mfnw-font-respon {
        font-size: 16px;
        text-align: center;
        @include media-breakpoints-up-lg {
            font-size: 20px;
        }
    }
}
.offer-font-sizerespon {
    font-size: 15px !important;
    @include media-breakpoints-up-md {
        font-size: 12px;
    }
}
.mfnw-box-one {
    display: flex;
    justify-content: flex-start;
    @include media-breakpoints-up-sm {
        flex-wrap: nowrap;
        width: unset;
        height: unset;
    }
}
.input-group {
    margin-bottom: 4px;
    @include media-breakpoints-up-lg {
        margin-bottom: 0;
    }
}
.responsive_shipper_btn {
    display: none;
    padding-top: 20px;
    @include media-breakpoints-up-lg {
        display: block;
    }
}
.react-datepicker-wrapper {
    width: 100%;
}
.responsive_shipper_btn_offer {
    display: none;
    padding-top: 20px;
    text-align: center;
    @include media-breakpoints-up-lg {
        display: none;
    }
}
.mfnw_responsive_one {
    display: none;
    text-align: center;
    @include media-breakpoints-up-lg {
        display: none;
    }
}
.main_detail_responsive {
    padding: 0px 30px;
    @include media-breakpoints-up-lg {
        padding: 0;
    }
}
.offer-btn {
    .btn_type_large {
        width: 230px;
        padding: 0px 10px;
        height: 46px;
        border-radius: 4px;
        @include media-breakpoints-up-lg {
            max-width: 200px;
            min-width: unset;
        }
    }
}

.btn-backgroud {
    background-color: $lightpink;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: normal;
    @include media-breakpoints-up-lg {
        background-color: $lightpink;
        border: none;
        border-radius: 4px;
    }
}
.mfnw_menu_title {
    display: flex;
    @include media-breakpoints-down-sm {
    }
}
.second-part {
    width: 20%;
}
.responsive_table {
    display: flex;
    justify-content: center;
    align-items: center;
}
.upper-part {
    border-bottom: 2px dashed #dddddd;
    align-items: center;
    @include media-breakpoints-up-sm {
        border-bottom: 2px dashed #dddddd;
        padding: 10px 0px;
        align-items: center;
    }
}
.division-section {
    @include media-breakpoints-up-lg {
    }
    .box-table {
        border-radius: 4px;
        border: 1px solid #ddd;
        display: inline;
        align-items: center;
        min-width: 80px;
        min-height: 50px;
        @include media-breakpoints-up-lg {
            border-radius: 4px;
            border: 1px solid #ddd;
        }
        .text {
            line-height: 14px;
        }
        .text-detail1 {
            line-height: 15px;
            font-weight: 700;
        }
    }
}
.Third-part {
    align-items: flex-start;
    margin-top: 20px;
    flex-direction: row;
    @include media-breakpoints-up-xxl {
        display: flex;
        align-items: flex-end;
        margin-right: 10px;
    }
    .upper-part {
        border-bottom: 2px dashed #dddddd;
        align-items: center;
        @include media-breakpoints-up-xxl {
            border-bottom: 2px dashed #dddddd;
            padding: 10px 0px;
            align-items: center;
        }
    }
    .new_responsive {
        flex-wrap: wrap;
        padding: 0px 0px 0px 5px;

        @include media-breakpoints-up-lg {
            flex-wrap: nowrap;
        }
    }
    .division-section {
        width: 200px;
        @include media-breakpoints-up-lg {
            width: 33.33333%;
        }
        .box-table {
            padding: 2px 8px 2px 8px;
            border-radius: 4px;
            border: 1px solid #ddd;
            display: inline;
            align-items: center;
            @include media-breakpoints-up-lg {
                padding: 2px 10px 2px 10px;
                border-radius: 4px;
                border: 1px solid #ddd;
            }
            .text {
                line-height: 14px;
            }
            .text-detail1 {
                line-height: 15px;
                font-weight: 700;
            }
        }
    }
}
// new offerlist
.mfnw__joblisting_content {
    padding: 1.5rem;
    .job_content_right {
        .origin {
            background: $lightpink;
            @include origin-dest-icon;
        }
    }
}
.job_content_right {
    padding: 0px;
    .origin {
        width: 30px;
        display: flex;
        @include media-breakpoints-up-lg {
        }
    }
}
.dropdown_filter-top {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: $white;
    border-radius: 4px;
    .btn-default {
        background-color: transparent;
        padding: 0px 1.5rem;
        font-size: 14px;
        &:hover {
            background-color: transparent;
        }
    }
}
.tbl-box1 {
    padding: 0px 2px;
    border: 1px solid #dddddd;
    @include table-boxborder;
    @include media-breakpoints-up-lg {
        border: 1px solid #dddddd;
        padding: 0px 16px;
        border-radius: 4px;
    }
}
.offer_box_responsive {
    width: 100px;
}
// third css
.table-one {
    .tbl-box-responsive {
        padding: 1px 20px;
        border: 1px solid #ddd;
        background-color: #f6f6f6;
        @include media-breakpoints-up-lg {
            padding: 1px 20px;
            border-radius: 4px;
            border: 1px solid #ddd;
            background-color: #f6f6f6;
        }
    }
    .frist_shipper_respon {
        min-width: 40px;
    }
    .table_responsive_lg {
        overflow-x: auto;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    }

    .tbl-box {
        padding: 13px 0px;
        width: 100px;
    }
    .table-font {
        font-weight: 400;
        text-transform: uppercase;
    }
}

// poppup design

.offer_add_modal {
    @include media-breakpoints-up-lg {
        width: 90%;
    }
    .title-tag {
        font-weight: 600px;
    }
    .icon {
        color: #0270b2;
        font-size: 20px;
    }

    .storage-details {
        width: 50%;
        .btn-check {
            width: 200px;
        }
    }
    .btn-option {
        width: 100px;
        padding: 5px 0px;
        border: 1px solid #ddd;
    }
    .second {
        background-color: #d5cdcd;
    }
    .mfnw_offer_radio_btn {
        .mfnw_one {
            @include mfnw_offer_radio_right;
        }
        .mfnw_second {
            @include mfnw_offer_radio_left;
        }
    }
    .mfnw__input-group-haul {
        // min-width: 73px;
        display: flex;
        font-size: 14px;
        padding: 10px;
        justify-content: center;
        align-items: center;
    }
    .input-group-text {
        background-color: #ddd !important;
        color: #363636;
    }
}
// tab css
.tab-div {
    color: #898989;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0;
    border: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}
.mfnw__borderless_card-table {
    width: 100%;
}
.table_responsive {
    display: flex;
    margin-top: 30px;
    @include media-breakpoints-up-lg {
        margin-top: 10px;
    }
}

.last_responsiveline {
    width: 300px;
    @include media-breakpoints-up-sm {
        width: unset;
    }
}
.offer_popup_button_responsive {
    display: flex;

    .custom_radio_button {
        min-width: 155px;
        @include media-breakpoints-up-sm {
            min-width: 210px;
        }
    }
}
.mfnw_moreinfo_frist {
    display: flex;
    @include media-breakpoints-up-lg {
        align-items: unset;
    }
}
.mfmw_offer_info {
    margin: 0px;
    @include media-breakpoints-up-xl {
        margin: 0 -12px;
    }
}
//
.offer_btn_set_min_size {
    min-width: 190px !important;
}
.lable_set_padding {
    padding-left: 1px;
}

@import '@scss/common.scss';

.mfnw__userlisting_viewmodal {
    .list_user {
        max-width: 320px;
        margin: 0 auto;
        .output_view_user {
            border: 1px solid $input-border-color;
            border-radius: 6px;
            font-size: 12px;
            .left_title {
                min-width: 120px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                background-color: $primary;
                color: #fff;
                text-align: center;
                font-weight: 600;
                padding: 15px 16px;
            }
            .right-content {
                padding: 15px 16px;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
                background-color: $bg-color;
                flex: 1;
                color: $text-dark;
                text-transform: capitalize;
                font-weight: 400;
                text-align: center;
            }
        }
    }
}
.mfp-main {
    .border-line {
        border-right: 1px solid rgb(182, 179, 179);
    }
}
.accordion-button::before {
    content: url('../../assets/img/down-arrow.svg');
    color: black;
    margin-right: 10px;
}

.accordion-button[aria-expanded='true']::before {
    content: url('../../assets/img/up-arrow.svg');
}

.accordion-button::after {
    display: none;
}

.overflow-text {
    height: 18px;
    width: 215px;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include media-breakpoints-up-sm {
        width: 250px;
    }
    @include media-breakpoints-up-md {
        width: 550px;
    }
    @include media-breakpoints-up-lg {
        width: 750px;
    }
}

@import '@scss/common.scss';

.forgot_pass_wrapper{
    min-height: 100vh;
}
.mfnw__form-fixed {
    margin: 0 auto;
    
    h3 {
        letter-spacing: 1.5pt;
    }
.mfnw_form-logoresponsive{
    display: none;
    @include media-breakpoints-up-lg {
                width: 315px;
                display: block;
            .mf-welcome-text {
                font-family: $font-family;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: $text-dark;
                margin-top: 30px;
            }
        }
}
}
.verifyDiv {
    .inputStyle {
        padding: 1rem 0rem;
        border: 1px solid #ddd;
        border-radius: 6px;
        margin: 0 auto;
        height: 3rem;
            width: 2.5rem !important;
        @include media-breakpoints-up-lg {
            height: 3.5rem;
            width: 3rem !important;
        }
    }
    .otpElements {
        max-width: 340px;
        margin: 0 auto;
        .otp > div {
            justify-content: space-around;
            gap: 10px;
        }
    }
}
.mfnw-right-footer-responsive{
        width: 100%;
       display: flex;
       justify-content: center;
        align-items: center;
        padding: 30px 0px;
        background: linear-gradient(
            188.68deg,
            #558dd1 0%,
            #0253B4 100%
        );
        background: linear-gradient(
            188.68deg,
            #639fea 0%,
            #0253B4 100%
        );
         span{
             font-family: $font-family;
             font-size: 14px;
             line-height: 18px;
             font-weight: 600;
             text-align: center;
             display: block;
             color: #fff;
         }
         @include media-breakpoints-up-lg {
            display: none;
       }    
     }
   